import React, { useState, useEffect } from 'react';
import { Col, Row, DatePicker, Spin, Select } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import SensorsPage from "./Sensors.styles";
import moment from "moment";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar,
    Rectangle
} from "recharts";
import { sensorService } from '../../../services/sensor.service';
import IntlMessages from '@iso/components/utility/intlMessages';
import { EditOutlined } from '@ant-design/icons';
import EditSensorNameModal from './EditSensorNameModal';
import { useSelector} from 'react-redux';

const { Option } = Select; 
const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY HH:mm:ss';
const rangePickerDateFormat = 'DD/MM/YYYY';
const defaultStartDate = moment().subtract(23, 'hours').subtract(59, 'minutes').format(dateFormat);
const defaultEndDate = moment().format(dateFormat);

const defaultTimelineOption = 'last_24_hours';

const Sensors = (props) => {
    const activePropertyId = useSelector(
        (state) => state.property.activePropertyId
      );

    const [sensors, setSensors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [charts, setCharts] = useState(null);
    const [timelineOption, setTimelineOption] = useState(defaultTimelineOption);
    const [editSensorNameModalVisible, setEditSensorNameModalVisible] = useState(false);
    const [editingSensorId, setEditingSensorId] = useState(null);
    const [editingSensorKey, setEditingSensorKey] = useState(null);
    const [editingSensorName, setEditingSensorName] = useState(null);

    useEffect(() => {
        getSensors();
    }, []);

    useEffect(() => {
        if (sensors.length > 0) {
            setLoading(true);

            const renderAllSensorsChart = async () => {
                const items = await Promise.all(sensors.map(renderEachSensorChart));
                return items;
            };

            renderAllSensorsChart().then((items) => {
                setCharts(items);
                setLoading(false);
            });
        } else {
            setCharts(null);
        }
    }, [sensors]);

    const getSensors = () => {
        setLoading(true);
        
        sensorService.getList({
            property_id: activePropertyId
        }).then((res) => {
            if (res.code === "0000") {
                setSensors(res.sensors);
                setLoading(false);
            }
        });
    }

    const getTimelineOptions = () => {
        return [
            { value: 'last_24_hours', label: 'Last 24 hours' },
            { value: 'today', label: 'Today' },
            { value: 'yesterday', label: 'Yesterday' },
            { value: 'last_7_days', label: 'Last 7 days' },
            { value: 'last_30_days', label: 'Last 30 days' },
            { value: 'this_month', label: 'This month' },
            { value: 'last_month', label: 'Last month' },
            { value: 'custom', label: 'Custom' },
        ];
    };

    const onTimelineOptionChange = (value) => {
        setTimelineOption(value);

        if (value !== 'custom') {
            switch (value) {
                case 'last_24_hours':
                    setStartDate(moment().subtract(23, 'hours').subtract(59, 'minutes').format(dateFormat));
                    setEndDate(moment().format(dateFormat));
                    break;
                case 'today':
                    setStartDate(moment().startOf('day').format(dateFormat));
                    setEndDate(moment().endOf('day').format(dateFormat));
                    break;
                case 'yesterday':
                    setStartDate(moment().subtract(1, 'days').startOf('day').format(dateFormat));
                    setEndDate(moment().subtract(1, 'days').endOf('day').format(dateFormat));
                    break;
                case 'last_7_days':
                    setStartDate(moment().subtract(7, 'days').format(dateFormat));
                    setEndDate(moment().format(dateFormat));
                    break;
                case 'last_30_days':
                    setStartDate(moment().subtract(30, 'days').format(dateFormat));
                    setEndDate(moment().format(dateFormat));
                    break;
                case 'this_month':
                    setStartDate(moment().startOf('month').format(dateFormat));
                    setEndDate(moment().endOf('month').format(dateFormat));
                    break;
                case 'last_month':
                    setStartDate(moment().subtract(1, 'month').startOf('month').format(dateFormat));
                    setEndDate(moment().subtract(1, 'month').endOf('month').format(dateFormat));
                    break;
                default:
                    break;
            }

            setSensors([...sensors]);
        }
    };

    const handleDateOpenChange = (open) => {
        if (open === false) {
            setSensors([...sensors]);
        }
    }

    const handleDateChange = (date, dateString) => {
        setStartDate(date[0].startOf('day').format(dateFormat));
        setEndDate(date[1].endOf('day').format(dateFormat));
    };

    const renderEachSensorChart = async (sensor) => {
        const params = {
            start_date: startDate,
            end_date: endDate,
        }

        let response = null;

        try {
            const res = await sensorService.getDetail(sensor.id, params);
            if (res.code === "0000") {
                response = <>
                    { renderSingleSensorChart('sensor1', sensor, res.data.items, res.data.data) }
                    { renderSingleSensorChart('sensor2', sensor, res.data.items, res.data.data) }
                    { renderSingleSensorChart('sensor3', sensor, res.data.items, res.data.data) }
                    { renderSingleSensorChart('sensor4', sensor, res.data.items, res.data.data) }
                    { renderSingleSensorChart('sensor5', sensor, res.data.items, res.data.data) }
                </>;
            }
        } catch (error) {
            console.error('Error fetching sensor details:', error);
        }

        return response;
    }

    const renderSingleSensorChart = (key, sensor, timelines, calculatedData) => {
        if (sensor[key + '_part_number'] === 'X50-14002') {
            const temperatureLineData = timelines.map((item) => ({
                label: item.start_time,
                value: item[key].temperature
            }));

            const humidityLineData = timelines.map((item) => ({
                label: item.start_time,
                value: item[key].humidity
            }));

            const temperatureBarData = [
                {
                    label: "Min",
                    value: calculatedData[key]['min_temperature']
                },
                {
                    label: "Max",
                    value: calculatedData[key]['max_temperature']
                },
                {
                    label: "Average",
                    value: calculatedData[key]['avg_temperature']
                },
                {
                    label: "Current",
                    value: timelines.at(-1)[key]['temperature']
                },
            ];

            const humidityBarData = [
                {
                    label: "Min",
                    value: calculatedData[key]['min_humidity']
                },
                {
                    label: "Max",
                    value: calculatedData[key]['max_humidity']
                },
                {
                    label: "Average",
                    value: calculatedData[key]['avg_humidity']
                },
                {
                    label: "Current",
                    value: timelines.at(-1)[key]['humidity']
                },
            ];

            return (
                <>
                    <div style={{ marginTop: 15, fontSize: 15, fontWeight: 500 }}>
                        <p>Device Name: {sensor.device_id}</p>
                        <p>Sensor Name: {sensor[key + '_name'] ?? key} <EditOutlined onClick={() => handleEditSensorName(sensor.id, key, sensor[key + '_name'])} /></p>
                        <p>Assigned Asset: N/A</p>
                    </div>

                    <Row gutter={15} className="manage-table-row" style={{ marginTop: 15 }}>
                        <Col xs={24}>
                            <h2 style={{ color: 'rgb(114, 157, 104)' }}>Temperature & Humidity Sensor</h2>
                        </Col>
                    </Row>
                    { renderTemperatureAndHumidityChart('Temperature', '°C', temperatureBarData, temperatureLineData) }
                    { renderTemperatureAndHumidityChart('Humidity', '%', humidityBarData, humidityLineData) }
                </>
            );
        }

        return null;
    }

    const renderTemperatureAndHumidityChart = (title, unit, barChartData, lineChartData) => {
        return (
            <Row gutter={15} className="manage-table-row">
                <Col xs={24}>
                    {title}
                </Col>

                <Col xl={6} md={24} sm={24} xs={24}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={barChartData}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="label" />
                            <YAxis unit={unit} />
                            <Tooltip formatter={(value) => `${value}${unit}`} />
                            <Legend />
                            <Bar
                                name={title}
                                dataKey="value"
                                fill="#B3CDAD"
                                activeBar={<Rectangle fill="pink" stroke="blue" />}
                                
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>

                <Col xl={18} md={24} sm={24} xs={24}>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart
                            data={lineChartData}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="label" />
                            <YAxis unit={unit} />
                            <Tooltip formatter={(value) => `${value}${unit}`} />
                            <Legend />
                            <Line
                                name={title}
                                type="monotone"
                                dataKey="value"
                                stroke="#729D68"
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
        );
    }

    const cancelEditSensorNameModal = () => {
        setEditSensorNameModalVisible(false);
    }

    const handleEditSensorName = (sensorId, sensorKey, sensorName) => {
        setEditingSensorId(sensorId);
        setEditingSensorKey(sensorKey);
        setEditingSensorName(sensorName);

        setEditSensorNameModalVisible(true);
    }

    const onEditSensorNameSaved = (newSensorName) => {
        setEditSensorNameModalVisible(false);

        sensorService.storeOrUpdate({
            sensor: {
                [editingSensorKey + '_name']: newSensorName
            }
        }, editingSensorId).then(res => {
            if (res.code === '0000') {
                const findSensor = sensors.find(sensor => sensor.id === editingSensorId);

                if (findSensor) {
                    findSensor[editingSensorKey + '_name'] = newSensorName;
                    setSensors([...sensors]);
                }
            }
        });
    }

    return (
        <LayoutWrapper>
            <SensorsPage>
                <Box id="containerBox">
                    <div className="manage-property-header">
                        <div className="mp-title">
                            <h2 className="manage-property-title"><IntlMessages id="sensorPage.title" /></h2>
                        </div>
                            
                        <div className="button-group">
                            <Select style={{ width: 140 }} defaultValue={timelineOption} onChange={onTimelineOptionChange}>
                                { getTimelineOptions().map((option, index) => (
                                    <Option key={index} value={option.value}>{option.label}</Option>
                                )) }
                            </Select>
                            
                            { timelineOption === 'custom' && <RangePicker
                                style={{marginLeft: '15px'}}
                                format={rangePickerDateFormat}
                                onChange={handleDateChange}
                                onOpenChange={handleDateOpenChange}
                            /> }
                        </div>
                    </div>

                    <div style={{padding: '15px'}}>
                        { loading ? (
                            <Spin style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} size="large" />
                        ) : (
                            charts
                        )}
                    </div>
                </Box>
            </SensorsPage>
            
            <EditSensorNameModal
                visible={editSensorNameModalVisible}
                sensorName={editingSensorName}
                onSave={onEditSensorNameSaved}
                onCancel={cancelEditSensorNameModal}
            />
        </LayoutWrapper>
    )
};

export default Sensors;