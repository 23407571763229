import React, { useEffect, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Input } from 'antd';
import EditSensorNameModalWrapper from './EditSensorNameModal.styles';

const EditSensorNameModal = ({ sensorName, visible, onSave, onCancel }) => {
    const [newName, setNewName] = useState(sensorName);

    useEffect(() => {
        setNewName(sensorName);
    }, [sensorName]);

    const handleSave = () => {
        onSave(newName);
    };

    const handleCancel = () => {
        setNewName(null);
        onCancel();
    };

    return (
        <EditSensorNameModalWrapper
            title={<IntlMessages id="sensorPage.editSensorNameModal.title" />}
            open={visible}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" className="cancel-button" onClick={handleCancel}>
                    <IntlMessages id="sensorPage.editSensorNameModal.cancel" />
                </Button>,
                <Button key="save" className="save-button" type="primary" onClick={handleSave}>
                    <IntlMessages id="sensorPage.editSensorNameModal.save" />
                </Button>
            ]}
        >
            <Input
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
            />
        </EditSensorNameModalWrapper>
    );
};

export default EditSensorNameModal;